<template>
  <v-container fluid class="SelectLanguage pa-0">
    <v-row class="main" no-gutters>
      <v-col cols="12" class="text-center text-section">
        <v-row>
          <v-col cols="12" class="header--text">Discover</v-col>
          <v-col cols="12" class="header--subtext">
            A specialty coffee that combining <br>the artistry of the blend with the science of the roast.
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-center button-section">
        <v-row justify="center">
          <v-col cols="6" xs="6" sm="4" md="2">
            <v-btn class="button-english" @click="setLang('en')" block rounded large>English</v-btn>
          </v-col>
          <v-col cols="6" xs="6" sm="4" md="2">
            <v-btn class="button-thai" @click="setLang('th')" block rounded large>ภาษาไทย</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <div class="logo-section">
        <img src="/Image/logo.png" alt />
      </div>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "SelectLanguage",
    methods: {
      setLang(lang) {
        this.$i18n.locale = lang;
        localStorage.setItem("casa_lang", lang);
        this.$router.push('/PhoneVerify');
      }
    }
  };
</script>

<style lang="scss">
  @import "@/assets/scss/color.scss";

  .SelectLanguage {
    background-image: url("/Image/BG.png");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 600px;
    height: 100%;

    .main {
      position: relative;
      min-height: 500px;
      height: 100%;
    }

    .text-section {
      height: 50%;
      color: white;
      padding: 0px 24px;
      align-items: center;
      display: flex;

      .header--text {
        font-size: 2em;
      }

      .header--subtext {
        font-size: 0.8em;
        word-wrap: break-word;
      }
    }

    .button-section {
      align-items: flex-end;
      padding: 0px 24px 10px 24px!important;
      display: flex;
      height: 50%;
      background-image: url("/Image/BG_3.png");
      background-size: contain;
      background-repeat: repeat;

      .button-english {
        font-weight: 800;
        color: white;
        background-color: $primary-color !important;
      }

      .button-thai {
        font-weight: 800;
        color: white;
        background-color: $base-color !important;
      }
    }

    .logo-section {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      position: absolute;
      background: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      padding: 10px;
      max-width: 150px;
      width: 40%;

      img {
        height: auto;
        width: 100%;
      }
    }
  }
</style>